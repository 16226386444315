<template>
  <ur-page-container class="msp" :show-title="true" title="예상 상속세" :topButton="false" @on-header-left-click="popupClose">

    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="tax-top-area tax-tab " >
        <mo-tab-box :default-idx="1" ref="defaultRef" class="ns-move-tab-box msp-tab-button" >
        <!-- <mo-tab-box :default-idx="selectTabNum" class="ns-move-tab-box msp-tab-button" > -->
          <mo-tab-label :idx="1" @click="tabClick(1)">현재</mo-tab-label>
          <mo-tab-label v-for="(data, j) in comprAge" :key="j" :idx="j+2" @click="tabClick(j+2)">{{ data }}세</mo-tab-label>
        </mo-tab-box>

        <div class="con-btn mt20">
          <mo-button class="ns-btn-round" @click="comprAgeChgOpen" >비교연령 변경</mo-button>
          <mo-button class="ns-btn-round" @click="ageExptIhrttaxComprOpen" ><mo-icon icon="msp-btn-chart" class="mr5"></mo-icon>연령별 비교</mo-button>
        </div>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="info-title-area">
          <div class="sub-title">{{ slctAge }} 결정세액</div>
          <div class="title">
            <strong>{{ exptIhrtAmt }}</strong>만원
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="tax-list">
          <mo-list :list-data="exptIhrtData">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <span class="title">{{item.data}}</span>
                    <span class="count"><strong>{{item.data1}}</strong>만원</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="terms-list-area">
          <li>상속재산의 간주/추정상속재산은 고려하지 않습니다.</li>
          <li>장례비는 1,000만원으로 가정하며, 미성년, 연로자, 장애인 공제는 未적용을 가정하였습니다. </li>
          <li>상속비율은 법적상속비율로 가정하였습니다.</li>
          <li>세대생략가산액은 고려하지 않으며, 신고세액공제는 3%(2023.08月 기준)를 적용하였습니다.</li>
          <li>실제 발생 금액과 차이가 발생할 수 있으며 법적인 효력이 없으니, 자세한 사항은 전문가와 상의하시기 바랍니다.</li>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
    <!-- Content영역 end -->
    <!-- Popup001 -->
    <mo-validate-watcher ref="comprAgeChgValidate">
      <mo-bottom-sheet ref="comprAgeChg"  class="ns-bottom-sheet closebtn">
        <template v-slot:title>
          비교연령 변경
          <div class="ns-btn-close" @click="comprAgeChgClose" name="닫기"></div>
        </template>
        <div class="content-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
            <ur-box-container v-for="(data, i) in chgComprAge" :key="i" alignV="start" componentid="" direction="column" class="mt20">
              <div class="row-text full">
                <mo-decimal-field numeric :rules="chgComprAgeValidation" v-model="chgComprAge[i]" max-numlength="3" class="form-input-name full input-clear-pr15" underline clearable/>
                <span class="right-text">세</span>
              </div>
            </ur-box-container>
          </ur-box-container>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="comprAgeChgClose" class="ns-btn-round white">취소</mo-button>
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" :disabled="disableBtnYn" @click="comprAgeChgSave" class="ns-btn-round blue">저장</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </mo-validate-watcher>
    <!-- Popup001 end -->
    <!-- Popup002 -->
    <mo-bottom-sheet ref="ageExptIhrttaxCompr"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        연령별 예상 상속세 비교
        <div class="ns-btn-close" @click="ageExptIhrttaxComprClose" name="닫기"></div>
      </template>
      <div class="content-area">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="income-year-chart">
          <mo-list :list-data="ageExptIhrttaxComprData">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <span class="text fs16rem">{{item.data}}</span>
                      <span class="count"><strong>{{item.data1}}</strong>만원</span>
                      <div class="bar-chart">
                        <div class="fill-bar" :style="{width:'calc('+item.data2+')'}"></div> <!--:style="width:"-->
                      </div>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="ageExptIhrttaxComprClose" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- Popup002 end -->
  </ur-page-container>
</template>
<script>
import cloneDeep from 'lodash.clonedeep';
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT401P",
    screenId: "MSPCT401P",
    components: {},
    props: {
      totalIhrtFrtun: {
        type: String,
        default: ''
      },
      fncFrtun: {
        type: String,
        default: ''
      },
      curAge: {
        type: String,
        default: ''
      },
      spuYn: {
        type: String,
        default: ''
      },
      chldFgr: {
        type: String,
        default: ''
      },
      fncAsetRsngRat: {
        type: String,
        default: ''
      },
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      if (this.curAge === '') {
        this.$emit('onPopupClose')
      } 
      this.comprAge = []
      this.chgComprAge = []
      for (let i = 1; i < 5; i++) {
        this.comprAge[i-1] = Number(this.curAge) + i * 5
      }
      this.chgComprAge = cloneDeep(this.comprAge)
      console.log('현재 비교연령>>', this.comprAge)
      console.log('변경 비교연령>>', this.chgComprAge)
      this.fn_exptIhrt()
      this.tabClick(1)
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        exptIhrtData: [
          {data:'일괄공제', data1: '1,000,020'},
          {data:'배우자공제', data1: '2,000,020'},
          {data:'금융재산공제', data1: '3,000,020'},
          {data:'과세표준금액', data1: '4,000,020'},
          {data:'산출세액', data1: '5,000,020'},
          {data:'자진신고할인', data1: '6,000,020'},
        ],
        ageExptIhrttaxComprData: [],
        year: '',
        selectTabNum: 1, // 선택한 탭
        // curAge: '', // 받아온 현재 나이
        routeData: {}, // 전 화면에서 받아온 데이터 모음
        exptIhrtAmt: '', // 예상상속세
        exptIhrtDataList: [], // 예상 상속세 계산결과 리스트
        comprAge: [], // 비교연령 리스트
        chgComprAge: [], // 변경 비교연령 리스트
        cloneComprAge: [], // 비교연령 임시저장 리스트
        disableBtnYn: false, // 버튼 비활성화 여부
        slctAge: '', // 선택한 나이
        chgComprAgeValidation: [
          v => (Number(this.curAge) < Number(v) || v.length === 0)  || '비교연령은 현재나이보다 높아야 합니다.',
          v => Number(v) <= 120 || '120세까지 입력 가능합니다.'
        ],
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      chgComprAge() {
        console.log('watch>', this.chgComprAge)
        let cnt = 0
        this.chgComprAge.forEach((e) => {
          if (e === '') {
            cnt++
          }
        })
        console.log('cnt>>>', cnt)
          if (cnt !== 4) {
            this.disableBtnYn = false
          } else {
            this.disableBtnYn = true
          }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      tabClick(val) {
        console.log('test>>', val)
        console.log('this.comprAge[val]>>', this.comprAge[val - 2])
        this.slctAge = val === 1 ? '현재' : `${this.comprAge[val - 2]}세`
        this.exptIhrtData[0].data1 = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[val-1].bndlSbrtAmt) // 일괄공제
        this.exptIhrtData[1].data1 = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[val-1].spuSbrtAmt) // 배우자공제
        this.exptIhrtData[2].data1 = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[val-1].fncFrtunSbrtAmt) // 금융재산공제
        this.exptIhrtData[3].data1 = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[val-1].taxsdAmt) // 과세표준금액
        this.exptIhrtData[4].data1 = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[val-1].prodtaxAmt) // 산출세액
        this.exptIhrtData[5].data1 = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[val-1].vlntyDclrDisc) // 자진신고할인
        this.exptIhrtAmt = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[val-1].exptIhrtAmt) // 예상상속세
      },
      // 상속세 계산 로직 함수
      fn_exptIhrt() {
        this.exptIhrtDataList = []
        let routeData = {
          totalIhrtFrtun: this.totalIhrtFrtun,
          fncFrtun :this.fncFrtun,
          curAge: this.curAge,
          spuYn: this.spuYn,
          chldFgr: this.chldFgr,
          fncAsetRsngRat: this.fncAsetRsngRat
        } 
        console.log('fn_exptIhrt routeData>>>', routeData)
        for (let i = 0; i <= this.comprAge.length; i++) {
          let ihrtPntm = i === 0 ? 0 : Number(this.comprAge[i-1]) - Number(this.curAge)
          this.exptIhrtDataList.push(this.$bizUtil.ctUtils.tobeCalExptIhrtTax(routeData, ihrtPntm))
        }
        console.log('결과값 확인>>>>', this.exptIhrtDataList)
      },
      comprAgeChgOpen() {
        this.chgComprAge = []
        this.cloneComprAge = cloneDeep(this.comprAge)
        this.chgComprAge = this.cloneComprAge
        for(let i=0; i < 4; i++) {
          if (this.chgComprAge.length === 4) {
            break
          } else {
            this.chgComprAge.push('')
          }
        }
        this.$refs.comprAgeChg.open()
      },
      comprAgeChgClose() {
        this.chgComprAge = this.cloneComprAge
        this.$refs.comprAgeChg.close() 
      },
      comprAgeChgSave() {
        this.$nextTick(() => {
          if (this.$refs.comprAgeChgValidate.validateResult === true) {
            this.comprAge = this.chgComprAge.filter((i) => {
              return i !== '' && i !== undefined
            })
            this.comprAge = [...new Set(this.comprAge)]
            this.comprAge.sort(function(a, b) {
              return Number(a) - Number(b)
            })
            if (this.comprAge.length > 0) {
              this.fn_exptIhrt()
              this.$refs.defaultRef.setIdx(1)
              this.$refs.comprAgeChg.close()
              this.tabClick(1)
              this.getStore('toast').dispatch('ADD', '적용되었습니다.')
            } else {
              return 
            }
          } else {
            return 
          }
        })
      },

      ageExptIhrttaxComprOpen() {
        this.ageExptIhrttaxComprData = []
        let t_arr = []
        let max = 0
        this.exptIhrtDataList.forEach((data) => {
          t_arr.push(data.exptIhrtAmt)
        })
        max = Math.max(...t_arr)
        for (let i = 0; i <= this.comprAge.length; i++) {
          let t_obj = {}
          if (i === 0) {
            t_obj.data = '현재'
          } else {
            t_obj.data = `${this.comprAge[i-1]}세`
          }
          t_obj.data1 = this.$bizUtil.numberWithCommas(this.exptIhrtDataList[i].exptIhrtAmt)
          t_obj.data2 = `${(this.exptIhrtDataList[i].exptIhrtAmt / max) * 100}%`
          this.ageExptIhrttaxComprData.push(t_obj)
        }
        this.$refs.ageExptIhrttaxCompr.open()
      },
      ageExptIhrttaxComprClose() { this.$refs.ageExptIhrttaxCompr.close() },
      popupClose() { this.$emit('onPopupClose') }
    }
  };
</script>
<style scoped>
</style>