<template>
  <ur-page-container class="msp" :show-title="true" title="상속세 계산하기" type="subpage" :topButton="true" @on-header-left-click="fn_goMain">
    <!-- Content영역 -->
    <mo-validate-watcher ref="totValidate">
      <ur-box-container direction="column" alignV="start" class="msp-ct-wrap tax-calculator">
        <!-- 퍼블추가 0523 고객명 영역 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area pal0">
          <ur-box-container v-if="custNm !== ''" alignV="start" componentid="" direction="row" class="gray-box bd-T-Ty1 mb0">
            <div class="fexTy5">
              <b class="fs18rem crTy-bk1">{{ custNm }}</b>
              <span class="ml4 fs16rem crTy-bk1">고객님</span>
            </div>
          </ur-box-container>
        </ur-box-container>
        <!-- 퍼블추가 0523 고객명 영역 // -->
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-add-area pb142">
          <ur-box-container alignV="start" componentid="" direction="column" class="mt15">
          <span class="label-title ty-large">[기본정보]</span>
          <div class="label-title ty-large must mt10">자산규모
            <!-- <span class="label-title ty-large must">자산규모</span>  <!-- 레이블 -->
          </div>
            <span class="">(금융+부동산-부채-임대보증금)</span>
            <div class="ns-certify-sed" :class="totalIhrtFrtunYn ? 'error' : ''"><!-- error class 추가로 상태변환 -->
              <div class="ns-certify-sed-code row-text">
                <mo-decimal-field numeric ref="totalIhrtFrtunRef"  class="form-input-name full input-clear-pr28" max-numlength="7" v-model="totalIhrtFrtun" :rules="totalIhrtFrtunValidation" underline clearable placeholder="금액을 입력해주세요." />
                <!-- <mo-text-field  class="form-input-name full" maxlength="9" v-model="totalIhrtFrtun" @blur="fn_focusOut('totalIhrtFrtun')" underline placeholder="금액을 입력해주세요." /> -->
                <span class="right-text">만원</span>
              </div>
              <!-- <div v-if="totalIhrtFrtunYn" class="ns-certify-sed-txt">
                <div class="txt-error">500억까지 입력 가능합니다.</div>
              </div> -->
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
            <span class="label-title ty-large must">현재나이</span>  <!-- 레이블 -->
            <div class="ns-certify-sed" :class="curAgeYn ? 'error' : ''"><!-- error  class 추가로 상태변환 -->
              <div class="ns-certify-sed-code row-text">
                <mo-decimal-field numeric  class="form-input-name full input-clear-pr15" max-numlength="3" v-model="curAge" :rules="curAgeValidation" underline clearable placeholder="나이를 입력해주세요." />
                <!-- <mo-text-field  class="form-input-name full" maxlength="3" v-model="curAge" @blur="fn_focusOut('curAge')" underline placeholder="나이를 입력해주세요." /> -->
                <span class="right-text">세</span>
              </div>
              <!-- <div v-if="curAgeYn" class="ns-certify-sed-txt">
                <div class="txt-error">100세까지 입력 가능합니다.</div>
              </div> -->
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
            <span class="label-title ty-large">[공제조건]</span>
            <span class="label-title ty-large mt10">금융자산규모</span>  <!-- 레이블 -->
            <div class="ns-certify-sed" :class="fncFrtunYn ? 'error' : ''"><!-- error class 추가로 상태변환 -->
              <div class="ns-certify-sed-code row-text">
                <mo-decimal-field numeric  class="form-input-name full input-clear-pr28" max-numlength="7" v-model="fncFrtun" :rules="fncFrtunValidation"  underline clearable placeholder="금액을 입력해주세요." />
                <!-- <mo-text-field  class="form-input-name full" maxlength="9" v-model="fncFrtun" @blur="fn_focusOut('fncFrtun')" underline placeholder="금액을 입력해주세요." /> -->
                <span class="right-text">만원</span>
              </div>
              <!-- <div v-if="fncFrtunYn" class="ns-certify-sed-txt">
                <div class="txt-error">금융재산은 총 상속재산을 초과할 수 없습니다.</div>
              </div> -->
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="mt30">
            <span class="label-title ty-large">배우자 여부</span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
              <mo-segment-wrapper solid primary v-model="spuYn" class="chip-type-wrap">
                <mo-segment-button value="0">배우자 없음</mo-segment-button>
                <mo-segment-button value="1">배우자 있음</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="mt50">
            <span class="label-title ty-large">자녀수</span>  <!-- 레이블 -->
            <msp-bottom-select class="ns-dropdown-sheet" :items="items1" v-model="chldCnt" underline bottom-title="자녀수" closeBtn scrolling placeholder="자녀수"/>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="mt20 mb50">
            <span class="label-title ty-large">[가정]</span>
            <span class="label-title ty-large mt10">자산상승률</span>  <!-- 레이블 -->
            <msp-bottom-select class="ns-dropdown-sheet" :items="items2" v-model="asRsngRat" underline bottom-title="금융자산상승률" closeBtn scrolling placeholder="자산상승률"/>
          </ur-box-container>

        </ur-box-container>

        
      </ur-box-container>
    </mo-validate-watcher>
    <!-- Content 영역 end -->

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative mb60">
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="calYn" @click="cal">계산하기</mo-button>
        <!-- <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="testpop">실손조회</mo-button> -->
      </div>
    </ur-box-container>
    
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPCT401P from '@/ui/ct/MSPCT401P'
  import MSPCT402P from '@/ui/ct/MSPCT402P'
  import Msg from '@/systems/webkit/msg/msg'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT400M",
    screenId: "MSPCT400M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
      this.fn_openMSPCT402P()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        totalIhrtFrtun: '', // 총 상속재산
        totalIhrtFrtunYn: false, // 총 상속재산 체크
        fncFrtun: '', // 금융재산
        fncFrtunYn: false, // 금융재산 체크
        curAge: '', // 현재나이
        curAgeYn: false, // 현재나이 체크
        spuYn: '0', // 배우자 여부
        chldCnt:'0', // 자녀수
        asRsngRat:'2', // 자산상승률
        calYn: true, // 계산하기 버튼 활성여부
        custNm: '', // 고객명
        fullPopup: {}, // 팝업
        isMSPCT402P: {}, // 고객검색

        totalIhrtFrtunValidation:[
          v => (Number(v) <= 5000000 && !this.$bizUtil.isEmpty(v) && Number(v) !== 0) || '500억까지 입력 가능합니다.'
        ],
        fncFrtunValidation: [
          v=> (Number(v) <= Number(this.totalIhrtFrtun)) || '금융재산은 총 상속재산을 초과할 수 없습니다.'
        ],
        curAgeValidation: [
          v => (Number(v) <= 100 && !this.$bizUtil.isEmpty(v) && Number(v) !== 0) || '100세까지 입력 가능합니다.'
        ]
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      items1() {
        let rtn = [];
        rtn.push({value: '0', text: '없음'});
        rtn.push({value: '1', text: '1명'});
        rtn.push({value: '2', text: '2명'});
        rtn.push({value: '3', text: '3명'});
        rtn.push({value: '4', text: '4명'});
        rtn.push({value: '5', text: '5명'});
        rtn.push({value: '6', text: '6명'});
        rtn.push({value: '7', text: '7명'});
        return rtn;
      },
      items2() {
        let rtn = [];
        rtn.push({value: '1', text: '1%'});
        rtn.push({value: '2', text: '2%'});
        rtn.push({value: '3', text: '3%'});
        rtn.push({value: '4', text: '4%'});
        rtn.push({value: '5', text: '5%'});
        return rtn;
      },
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      totalIhrtFrtun() { this.totValidation()},
      fncFrtun() { this.totValidation()},
      curAge() { this.totValidation()}
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_openMSPCT402P
      * 설명       : 고객검색 팝업 노출
      ******************************************************************************/
      fn_openMSPCT402P() {
        this.isMSPCT402P = this.$bottomModal.open(MSPCT402P, {
          properties: {
          },
          listeners: {
            onPopupConfirm: (param) => {
              this.custNm = param
              this.$bottomModal.close(this.isMSPCT402P);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.isMSPCT402P)
              this.$router.go(-1)
            },
            goMSPCM050M: (param) => {
              this.$router.push({ name: 'MSPCM050M', params : { custNm: param, srnId: this.$options.screenId, aprvType: '01' }})
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },

      totValidation() {
        this.$nextTick(() => {
          if (!this.$bizUtil.isEmpty(this.totalIhrtFrtun) && !this.$bizUtil.isEmpty(this.curAge)
              && this.totalIhrtFrtun !== '0' && this.curAge !== '0') {
                this.calYn = false
              if (this.$refs.totValidate.validate() === true) {
                return
              } else {
                this.calYn = true
              }
          } else {
                this.calYn = true
          }
        })
      },

      /******************************************************************************
      * Function명 : cal
      * 설명       : 계산하기
      ******************************************************************************/
      cal() {
        console.log('계산하기')
        let p_totalIhrtFrtun = this.totalIhrtFrtun.replaceAll(',','') // 총 상속재산
        let p_fncFrtun = this.fncFrtun.replaceAll(',','') // 금융재산
        let p_spuYn = this.spuYn // 배우자여부
        let p_chldFgr = this.chldCnt // 자녀수
        let p_fncAsetRsngRat = this.asRsngRat // 자산상승률(%)
        if (!this.$bizUtil.isEmpty(this.totalIhrtFrtun) && !this.$bizUtil.isEmpty(this.curAge)
            && this.totalIhrtFrtun !== '0' && this.curAge !== '0' && this.$refs.totValidate.validate() === true) {
        } else {
          return
        }
        this.fullPopup = this.$bottomModal.open(MSPCT401P, {
          properties: {
            totalIhrtFrtun: p_totalIhrtFrtun,
            fncFrtun :p_fncFrtun,
            curAge: this.curAge,
            spuYn: p_spuYn,
            chldFgr: p_chldFgr,
            fncAsetRsngRat: p_fncAsetRsngRat,
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.fullPopup);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.fullPopup);
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },

      /******************************************************************************
      * Function명 : fn_goMain
      * 설명       : 메인이동
      ******************************************************************************/
      fn_goMain() {
        console.log('메인이동')
        this.$router.push({ name: 'MSPBC002M' })
      }
    }
  }
</script>
<style scoped>
</style>