/*
* 업무구분: 
* 화면 명: 상속계산기 고객검색
* 화면 설명: 상속계산기 고객검색
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" :show-title="true" type="subpage" title="고객검색" :topButton="false" @on-header-left-click="popupClose">

    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid=""  direction="column" class="con-area mt10">
        <mo-text-field v-model="searchCustNm"  class="form-input-name full mb10" underline ref="searchCustNm"
         searchable placeholder="고객명을 입력해 주세요." 
         @keyup.enter="fn_searchCustNm" @click-icon="fn_searchCustNm" clearable />
        <ul class="terms-list-area crTy-bk7 fs14rem">
          <li>상속계산을 위한 마케팅 동의는 2023.08.01 이후 동의한 고객에 한해 <b class="crTy-blue2">Y</b> 로 표시됩니다.</li>
        </ul>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum mt10">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title mb0 align-item-center">
              <div class="">
                <span v-if="!isSearchYn" class="fs16rem fwb">최근 검색한 고객</span>
                <span v-else class="sum mt0">총<span class="count">{{ custInfoList.length }}</span>명</span>
              </div>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <ur-box-container v-if="custInfoList.length > 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-radio-list2 list-item-p2024"> <!-- 퍼블 0522 수정 ns-radio-list2 -->
        <mo-list :list-data="custInfoList">
          <template #list-item="{item, index}">
            <mo-list-item :class="{on: index === slctIdx}">
              <mo-radio v-model="radioValue" :value="String(index)" @input="fn_SelectCust(item, index)"/> <!-- 퍼블 0522 추가 mo-radio -->
              <div class="list-item__contents" @click="fn_SelectCust(item, index)">
                <div class="list-item__contents__title">
                  <span class="name txtSkip-inline" >
                    <span v-if="isSearchYn" v-html="fn_getHighlightTitle(item.custNm)" @click.stop="fn_PopupCustNmUiCard(item.chnlCustId)"></span>
                    <span v-else @click.stop="fn_PopupCustNmUiCard(item.chnlCustId)">{{ item.custNm }}</span>
                  </span>
                </div>
                <div class="list-item__contents__info">
                  <div class="fexTy5"> <!-- 퍼블 0522 추가 fexTy5  -->
                    <span class="crTy-bk1">{{ `${item.dob.substring(0,4)}-**-**` }}</span><em>|</em>
                    <span class="crTy-bk1">{{ item.sxcdNm }}</span><em>|</em>
                    <span class="crTy-bk1">{{ item.age }}세</span>
                  </div>
                </div>
                <div class="list-item__contents__info">
                  <div class="fexTy5"> <!-- 퍼블 0522 추가 fexTy5 -->
                    <span class="crTy-bk1">마케팅동의
                      <b class="ml10" :class="item.mktCnsntYn === 'Y' && Number(item.mktCnsntStrYmd)>= 20230801 ? 'crTy-blue2' : 'crTy-red2'">
                        {{ item.mktCnsntYn === 'Y' && Number(item.mktCnsntStrYmd)>= 20230801 ? 'Y' : 'N' }}
                      </b>
                    </span>
                  </div>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>

      <!-- no-data 최근 검색결과가 없습니다. -->
      <template v-else><!--custInfoList.length === 0 -->
        <ur-box-container v-if="!isSearchYn" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info small-space">
                <span class="ns-ftcr-gray">
                  <p class="mt20 mb20">최근 검색결과가 없습니다.</p>
                </span>            
              </div>
            </div>
          </mo-list-item>
        </ur-box-container> 
        <!-- no-data '홍길'에 대한 검색결과가 없습니다. --> 
        <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info small-space">
                <span class="ns-ftcr-gray">
                  <p class="mt20 mb20"><b  class="crTy-bk1">{{ searchCustNmRslt }}</b>에 대한 검색결과가 없습니다.</p>
                  <mo-button class="ns-btn-round h28 blackW fs14rem" @click="fn_goMSPCM050M">신규고객 등록</mo-button>
                </span>            
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </template>
    </ur-box-container>
    <!-- Content영역 end -->

   

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    isBackKeyHandle: true,
    components: {},
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.popupClose)
      this.fn_searchCustNm()
    },
    mounted() {
      this.$refs.searchCustNm.focus()
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.popupClose)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        custInfoList: [],
        slctIdx: 0,
        radioValue: '',
        searchCustNm: '', // 검색고객명
        searchCustNmRslt: '', // 검색고객명 결과
        isSearchYn: false, // 검색여부
        isCustInfoBottomSheet: {}, // 고객카드

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      open() { this.$refs.nsbottomsheet.open();  },
      close() { this.$refs.nsbottomsheet.close(); },

      open1() { this.$refs.nsbottomsheet1.open();  },
      close1() { this.$refs.nsbottomsheet1.close(); },
      popupClose() { this.$emit('onPopupClose') },

      fn_getHighlightTitle (val) {
        if (!this.searchCustNm) {
          return val
        }
      const regex = new RegExp(`(${this.searchCustNm})`, 'gi')
      return val.replace(regex, '<span class="crTy-blue3">$1</span>')
      },

     /******************************************************************************
      * Function명 : fn_PopupCustNmUiCard
      * 설명       : 고객 네임카드 오픈
      ******************************************************************************/
      fn_PopupCustNmUiCard (pChnlCustId) {
        console.log('pChnlCustId>>', pChnlCustId)
        //to-do 고객네임카드 연계 화면 처리
        if(pChnlCustId !== '-'){
          this.isCustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
            properties: {
              chnlCustId: pChnlCustId,
              cnstlNo: this.getStore('userInfo').getters.getUserInfo.userId,
              //contNo : contNo
              parentId: 'MSPCT402P'
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              },
              onPopupClose: () => {
                this.radioValue = ''
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              }
            }
          })
        }
      },

      /*******************************************************************************
      * Function명: fn_searchCustNm
      * 설명: 고객 검색
      *******************************************************************************/
      fn_searchCustNm: _.debounce(function() {
        console.log('고객검색')
        let lv_vm = this
        if (this.searchCustNm.length > 0 && this.searchCustNm.length  < 2) {
          console.log('고객검색XXXXX')
          return
        }
        let pParams = { custNm: this.searchCustNm }
        let trnstId = 'txTSSCT22S1'
        const auth = 'S'
        this.custInfoList = []
        this.isSearchYn = false
        this.post(lv_vm, pParams, trnstId, auth).then(response => {
          console.log('res>>', response)
          lv_vm.searchCustNmRslt = lv_vm.searchCustNm
          if (lv_vm.searchCustNm.length === 0) {
            lv_vm.isSearchYn = false
          } else {
            lv_vm.isSearchYn = true
          }
          if (response?.body?.cTCustInfoDtlSVO) {
            lv_vm.custInfoList = response.body.cTCustInfoDtlSVO
          }
        }).catch(error => {
          window.vue.error(error)
        })
      }, 500),

      /*******************************************************************************
      * Function명: fn_SelectCust
      * 설명: 고객 선택
      *******************************************************************************/
      fn_SelectCust(item, idx) {
        console.log('선택', idx)
        console.log('선택고객정보', item)
        this.radioValue = String(idx)
        //if (true) { // 마케팅동의 Y
        if (item.mktCnsntYn === 'Y' && Number(item.mktCnsntStrYmd)>= 20230801) { // 마케팅동의 Y
          let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: false,
            title: "",
            content: `${item.custNm} 고객님의 상속계산을 <br/> 진행하시겠습니까?`,
            title_pos_btn: "진행",
            showCloseBtn: true,
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              this.fn_searchCustSave(item)
              //this.$emit('onPopupConfirm', item.custNm)
            },
            onPopupCancel: () => { // X버튼 클릭
              this.radioValue = ''
              this.$bottomModal.close(lv_AlertPop)
            },
            onPopupClose: () => { // 취소버튼 클릭
              this.radioValue = ''
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
        } else { // 마케팅동의 N
          let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              single: true,
              title: "",
              content: '마케팅 동의가 필요한 고객입니다. <br/> *상속계산 이용을 위해서는 2023.08.01 이후 마케팅 동의가 필요합니다.',
              title_pos_btn: "마케팅 동의",
              showCloseBtn: true,
            },
            listeners: {
              onPopupConfirm: () => {
                this.$emit('goMSPCM050M', item.custNm)
                this.$bottomModal.close(lv_AlertPop)
              },
              onPopupCancel: () => {
                this.radioValue = ''
                this.$bottomModal.close(lv_AlertPop)
              }
            }
          })
        }
      },

      /*******************************************************************************
      * Function명: fn_searchCustSave
      * 설명: 선택 고객 저장
      *******************************************************************************/
      fn_searchCustSave(param) {
        let lv_Vm = this
        const trnstId = 'txTSSBC02I1'
        const auth = 'I'
        // 옵션 Input 셋팅
        let pParams = {
          'chnlCustId': param.chnlCustId,
          'custNm': param.custNm,
          'dob': param.dob,
          'telno': param.telno,
        }
        console.log('고객선택 pParams>>>', pParams)

        this.post(lv_Vm, pParams, trnstId, auth, {}, true)
          .then(function (response) {
            if (response.msgComm.msgCd === 'ECOU002') { // ECOU002:저장되었습니다
              lv_Vm.$emit('onPopupConfirm', param.custNm)
            }
          }) 
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /*******************************************************************************
      * Function명: fn_goMSPCM050M
      * 설명: 신규 고객 등록
      *******************************************************************************/
      fn_goMSPCM050M() {
        this.$emit('goMSPCM050M')
      }
    }
  };
</script>
<style scoped>
</style>